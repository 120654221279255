<template>
  <div v-if="issuesInProduction">
    <h3 class="content-header-title float-left pr-1 mb-0" style="margin-right:1rem;"> 
      Issues In Production
    </h3>
    <h5 class="float-left" style="padding-top:.3rem;"> 
      {{ issuesInProduction.length }}
    </h5>
    <!--following needed because of prior elements 'float-left'-->
    <b-row><b-col><br></b-col></b-row>
    <br>
    <b-card bg-variant="light">     
      <b-row>
        <b-col v-for="(item, index) in issuesInProduction" :key="index" cols="4">
          <b-card>
            <h4><router-link  :to="issueRouterName(item)">{{ issueName(item) }}</router-link></h4>
            <br>
            <b-row>
              <b-col cols="5"><strong>Articles</strong></b-col> 
              <b-col cols="4">{{ item.noArticles | formatNumber }}</b-col>
            </b-row>
            <b-row >
              <b-col cols="5"><strong>Files</strong></b-col> 
              <b-col cols="4">{{ item.noFiles | formatNumber }}</b-col>
            </b-row>
          </b-card>
        </b-col>
        <br>
      </b-row>
    </b-card>
  </div>
</template> 
 
<script>

import publicationApi from '@/dl_pubmill/apis/publication'
import Ripple from 'vue-ripple-directive'

import {
  BCard, BRow, BCol,
} from 'bootstrap-vue'

export default {

  components: {
    BCard,
    BRow,
    BCol
  },
  directives: {
    Ripple,
  },
  data () {
    return {
      issuesInProduction: null,
      format: null,
      intakeRecord: null,
      dropPointArticles: null
    }
  },
  computed: {
    intakeErrors () {
      if (!this.intakeRecord) {
        return null
      }
      let returnVal = null
      if (!this.intakeRecord.doi || this.intakeRecord.doi.trim().length === 0) {
        returnVal = 'DOI Required' 
      } else if (!this.intakeRecord.doi.includes('/')) {
        returnVal = 'Invalid DOI format'
      }
      if (!this.intakeRecord.title || this.intakeRecord.title.trim().length === 0) {
        if (!returnVal) {
          returnVal = 'Article Title missing'
        } else {
          returnVal += ', Article Title missing'
        }
      }
      return returnVal
    },
  },
  watch: {
    $route: {
      //watch on route changes is only needed when same .vue is enteredfrom multiple nav points 
      handler (newVal, oldVal) {
        console.log('route:', this.$route.name, 'newVal:', newVal.name, 'oldVal:', oldVal)
        this.refreshData()
      }
    }, 
  },
  mounted () {
    this.refreshData()
  },
  methods: {
    articleTitle (article) {
      let title = article.title
      if (title.length === 0) {
        return 'TITLE NOT FOUND'
      } 
      if (title.length > 130) {
        title = title.substring(0, 129).concat('...')
      } 
      //will render text as html, therefore sanitize first
      //console.log('aaaaaaav', this.$sanitize(title))
      return this.$sanitize(title)

    },
    issueRouterName (item) {
      return {
        name: 'production-issue-admin', 
        params: {
          issn: this.$route.params.issn,
          vol: item.vol,
          issue: item.iss
        }
      }
    },
    issueName (item) {
      if (item.vol === '0') return 'Pending Issue'
      return 'Volume: '.concat(item.vol, ' -  Issue: ', item.iss)
    },

    getIssuesInProduction () {
      publicationApi.getIssuesInProduction(this.$route.params.issn, this, null)
    },
    backFromGetIssuesInProduction (serverData) {
      //console.log('bbbbbbb', serverData)
      if (serverData.error) {
        console.log(serverData.error)
      } else {
        this.format = serverData.format
        this.issuesInProduction = []
        for (let i = 0; i < serverData.data.length; i++) {
          this.issuesInProduction.push(serverData.data[i])
        }  
      } 
    },
    refreshData () {
      this.getIssuesInProduction()
    }
  },
}
</script>
